export function getCancellations() {
    return axios({
        url: `/api/cancellations`,
        method: 'get',
    });
}

export function createCancellation(orderId, data) {
    let form_data = new FormData();
    form_data.append('order_id', orderId);
    for (let key in data) {
        form_data.append(`data[${key}]`, data[key]);
    }
    console.log(form_data)
    return axios.post(`/api/cancellations`, form_data,{
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
